
/*! VUD Icons (Visma Unified Design) - v1.7.1 */

//# path overwite and units setup;
$prefix-path: '..' !default;
$vud-units: 'px' !default;

// VUD icons path
$vud-icons-path: '#{$prefix-path}/img/vismaicons' !default;


// Core VUD variables and mixins
@import 'icons/icons-variables';

@import 'settings/variables-light-dark-mode';

// SCSS for icons
@import 'icons/icons-base';

//== Import all icons
// worksurface
@import 'icons/icons-worksurface-standard';
@import 'icons/icons-worksurface-dynamic';

// action
@import 'icons/icons-action-standard';
@import 'icons/icons-action-dynamic';

// informative
@import 'icons/icons-informative-standard';
@import 'icons/icons-informative-dynamic';

// social media
@import 'icons/icons-social-media';

// flags
@import 'icons/icons-flags';